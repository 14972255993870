import * as React from 'react'
import styled from '@emotion/styled';
// import { Link } from 'gatsby';
import Layout from '../components/layout/layout';
// import { Button } from '../components/button/button';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto 2rem;
    text-align: center;
`

const Heading = styled.h1`
    margin-bottom: 0;
`

const SubscribedPage = () => {
    return ( 
        <Layout pagetitle="Successfully Subscribed" showNav={true}>
            <Container>
                <Heading>Success! </Heading>
                <h3>Please check your email. 🥳</h3>
                {/* <Link to='/blog'>
                    <Button>Back to the Posts</Button>
                </Link>     */}
            </Container>
        </Layout>
     );
}
 
export default SubscribedPage;